import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { uniqueId } from 'lodash/fp';
import { equals, hasPath, isNil, pipe, propEq, reject, values } from 'ramda';
import React, {
  FC,
  FormEvent,
  Fragment,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';
import { FiAlertTriangle, FiXOctagon } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useUIDSeed } from 'react-uid';
import { v4 as uuidv4 } from 'uuid';

import { Accordion, AccordionItem } from '../components/Accordion';
import { Anchor } from '../components/Anchor';
import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { Checkbox } from '../components/Checkbox';
import { FileInput } from '../components/FileInput';
import { Input } from '../components/Input';
import { Label } from '../components/Label';
import { ListItem, Ul } from '../components/List';
import { H4, H5, Typography } from '../components/Typography';
import { useModal } from '../contexts/filerModal';
import SuccessImage from '../images/success.png';
import {
  createCitation as createCitationAction,
  getSignedUrl as getSignedUrlAction,
  saveTemporaryCaseId,
  saveTemporaryFile,
  uploadDocument as uploadDocumentAction,
} from '../lib/actions/documents';
import { Paths } from '../paths';
import { RootState } from '../reducers';
import { requiredInfoForUploadingSelector, userIdSelector } from '../selectors/user';
import { Paragraph } from '../styles/common';
import { generateGuestToken, tryAutomaticDownload } from '../utils/function-utils';
import { Colors } from '../utils/style-utils';
import { Container, HelpText, Loading } from './styles';

type Entry = {
  id: string;
  file: File | null;
  signedUrl?: string;
};

type ProcessError = {
  citation: string;
  error_type: 'id-ibid' | 'no-results' | 'multiple-results';
  citation_instances: {
    citation_text: string;
    citation_text_extended: string;
  }[];
  documents?: {
    document_number: string | null;
    document_subtype: string;
    document_title: string | null;
    document_type: string | null;
    filed_date: string;
    is_supplemental: boolean;
    link: string;
    transcript_date: string | null;
    uniquedocid: number;
  }[];
};

enum UploadStep {
  Prepare,
  Upload,
  Process,
  Success,
}

const isPreparing = equals(UploadStep.Prepare);
const isUploading = equals(UploadStep.Upload);
const isProcess = equals(UploadStep.Process);
const isSuccess = equals(UploadStep.Success);

const InputLabel = (
  <Label>
    <span className="mr-2">Enter the full case id</span>
    <HelpText>(eg. &ldquo;2 CA-CV 2019-0109&rdquo;)</HelpText>
  </Label>
);

const rejectValid = pipe(values, reject(Boolean));

export const UploadForm: FC = () => {
  const dispatch = useDispatch();
  const seed = useUIDSeed();
  const { open } = useModal();
  const location = useLocation();
  const userId = useSelector(userIdSelector);
  const hasRequiredInfo = useSelector(requiredInfoForUploadingSelector);
  const tmpFile = useSelector((root: RootState) => root.documents.tmpFile);
  const tmpCase = useSelector((root: RootState) => root.documents.tmpCase);
  const formRef = useRef<HTMLFormElement>(null);
  const [caseId, setCaseId] = useState(tmpCase);
  const [record, setRecord] = useState<Entry>({ id: uniqueId('input_'), file: tmpFile });
  const [formStep, setFormStep] = useState(UploadStep.Prepare);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [progress, setProgress] = useState(0);
  const [downloadLink, setDownloadLink] = useState('');
  const [linkingErrors, setLinkingErrors] = useState<ProcessError[]>([] as ProcessError[]);
  const [selectedFiles, setSelectedFiles] = useState<Record<string, string[]>>({});
  const [briefKey, setBriefKey] = useState('');
  const isAnonymous = isNil(userId);

  useEffect(() => {
    if (progress === 100) {
      if (isUploading(formStep)) {
        if (linkingErrors.length > 0) {
          setFormStep(UploadStep.Process);
        } else {
          setFormStep(UploadStep.Success);
          tryAutomaticDownload(downloadLink);
        }
      }

      setProgress(0);
    }
  }, [progress]);

  function onKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
    if (evt.key === 'Enter') {
      setFormStep(UploadStep.Upload);
    }
  }

  useEffect(() => {
    async function upload() {
      if (isUploading(formStep)) {
        const resubmission = !!briefKey;
        let briefS3Key;
        let inputLinks = undefined;

        if (!briefKey) {
          const docsUuid = uuidv4();
          const anonUserId = `anonymous-${generateGuestToken()}`;

          try {
            const result = (await dispatch(
              getSignedUrlAction(record.file!.name, docsUuid, userId || anonUserId, !isAnonymous),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            )) as unknown as any;

            await dispatch(
              uploadDocumentAction(result.data.url, result.data.fields, record.file as File),
            );
            briefS3Key = result.url!.replace(/(^\w+:|^)\/\//, '');
          } catch (err) {
            console.error(err);
            toast.error('Document could not be uploaded', {
              duration: 10000,
              icon: <FiXOctagon className="min-w-icon w-8" size={32} color="#D0312D" />,
            });
            setFormStep(UploadStep.Prepare);
          }

          if (!briefS3Key) return;
          setBriefKey(briefS3Key);
        } else {
          if (rejectValid(selectedFiles).length > 0) {
            toast(
              <span className="flex items-center">
                <FiAlertTriangle color={Colors.Blue900} className="mr-2" />
                Please review all warnings for pertinent actions
              </span>,
            );
            setFormStep(UploadStep.Process);
            return;
          }

          briefS3Key = briefKey;
          inputLinks = { ...selectedFiles };
        }

        dispatch(saveTemporaryCaseId(''));
        dispatch(saveTemporaryFile(null));

        try {
          const result = (await dispatch(
            createCitationAction(
              briefS3Key.substring(briefS3Key.indexOf('/') + 1),
              caseId,
              !isAnonymous,
              inputLinks,
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          )) as unknown as any;

          if (result.errors && !resubmission) {
            setLinkingErrors(result.errors);
            setSelectedFiles(
              (result.errors as ProcessError[])
                .filter(propEq('error_type', 'multiple-results'))
                .reduce((acc, next) => {
                  // Setting default values
                  acc[next.citation] = next.citation_instances.map(() => next.documents[0].link);
                  return acc;
                }, {} as Record<string, string[]>),
            );
          } else if (resubmission) {
            setLinkingErrors([] as ProcessError[]);
            setSelectedFiles({} as Record<string, string[]>);
          }
          setDownloadLink(result.url);
          setProgress(100);

          if (!isAnonymous && (resubmission || !result.errors)) tryAutomaticDownload(result.url);
        } catch (err) {
          if (hasPath(['message', 'case_id'], err)) {
            toast.error((err as { message: { case_id: string[] } }).message.case_id.join(', '), {
              duration: 10000,
              icon: <FiXOctagon className="min-w-icon w-8" color="#D0312D" />,
            });
          } else if (typeof (err as Error).message === 'string') {
            let changedMsg="";
            if ((err as Error).message.includes("Either your bar number is not correct")) {
              changedMsg = (
                <span>
                  e-Filer key or bar number is invalid. Are you sure it’s up to date? Click{" "}
                  <Anchor style={{ cursor: 'pointer', color: Colors.Purple300 }} onClick={open}>
                    here
                  </Anchor>
                  {" "}for renewal instructions.
                </span>
              );
            }
            toast.error(<span>{changedMsg ? changedMsg : (err as Error).message}</span>, {
              duration: 10000,
              icon: <FiXOctagon className="min-w-icon w-8" size={32} color="#D0312D" />,
            });

          } else {
            toast.error('Document could not be hypercited', {
              icon: <FiXOctagon className="min-w-icon w-8" size={32} color="#D0312D" />,
            });
          }

          setFormStep(UploadStep.Prepare);
        }
      }
    }

    upload();
  }, [formStep]);

  function renderHelpText(): JSX.Element | null {
    if (formStep === UploadStep.Prepare && !isNil(record.file)) {
      return <HelpText positive>Looks good!</HelpText>;
    }

    return null;
  }

  function resetForm() {
    setRecord({ id: uniqueId('input_'), file: null, signedUrl: '' });
    setFormStep(UploadStep.Prepare);
    setProgress(0);
    setBriefKey('');
    setSelectedFiles({});
    formRef.current?.reset();
    dispatch(saveTemporaryCaseId(''));
    dispatch(saveTemporaryFile(null));
  }

  function onSubmit(evt: FormEvent) {
    evt.preventDefault();
    setFormStep(UploadStep.Upload);
  }

  function renderActions() {
    if (!isPreparing(formStep)) return null;

    let infoIncomplete = isNil(record.file) || !caseId.trim();

    if (isAnonymous) {
      infoIncomplete = infoIncomplete && !acceptTerms;
    }

    function checkOrUpload() {
      if (isAnonymous) {
        void navigate(`${Paths.SignUp}?redirect=${Paths.Home}`);
      } else if (!hasRequiredInfo) {
        open();
        void navigate(`${location.pathname}?requiresKey=true`, { replace: true });
      } else {
        setFormStep(UploadStep.Upload);
      }
    }

    return (
      <div className="flex flex-row justify-between mt-4">
        <Button className="ml-auto" onClick={checkOrUpload} disabled={infoIncomplete}>
          Process
        </Button>
      </div>
    );
  }

  function renderLoading() {
    if (isUploading(formStep)) {
      return (
        <Container>
          <Loading className="flex flex-col items-center justify-center">
            <H4 className="mb-20 text-center">Uploading document and Hyperciting</H4>

            <H5 className="mt-6 text-center">Uploading 1 document...</H5>
          </Loading>
        </Container>
      );
    }

    if (isSuccess(formStep)) {
      return (
        <Container>
          <Loading className="flex flex-col items-center justify-center">
            <H4 className="mb-20 text-center">Your files have been Hypercited</H4>

            <img src={SuccessImage} alt="citation succeeded" />

            <HelpText className="text-center" style={{ marginTop: '4rem' }}>
              File will automatically download.
              <br />
              Didnt get the file? <Anchor href={downloadLink}>Download it.</Anchor>
            </HelpText>

            <div className="flex flex-row justify-center mt-4">
              <Button variant="primary" onClick={resetForm}>
                Start Over
              </Button>
            </div>
          </Loading>
        </Container>
      );
    }

    return null;
  }

  if (isProcess(formStep)) {
    return (
      <Container className="relative flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-4 text-center">Your files have been Hypercited</H4>
        <H5 className="mb-10 text-center">
          However, we have compiled a list of action items
          <br />
          you may want to address to ensure the best result
        </H5>

        <div className="w-full flex flex-row justify-center">
          <img src={SuccessImage} alt="citation succeeded" />
        </div>

        <Accordion autoFocus={false} className="w-full px-4" size="sm">
          {linkingErrors.map((linkingError) => {
            let message: string | ReactElement = '';

            switch (linkingError.error_type) {
              case 'id-ibid':
                message =
                  'We have noticed you employ id. citations to direct the court to a previously cited document. In order for HyperCite to transform id citations to previously cited docket documents (ROA and documents filed in the COA), please replace those cites with COA-id. All other id. cites, such as those referring to cases, statutes, or secondary sources, remain the same.';
                break;
              case 'no-results':
                message = (
                  <span>
                    We believe the above is a citation, but we are unable to match it with any
                    document found in the docket. If it is not a citation, ignore this message. If
                    it is, please double check the citation for correctness. The user-guide covers
                    virtually any type of citation you would run into, and if after its review, you
                    still require assistance, please{' '}
                    <Anchor href="mailto:help@hypercite.net">reach out</Anchor>
                  </span>
                );
                break;
              default:
                message =
                  'We have found multiple documents within the docket with which the above citation could refer. Please select the document you intended to cite from the below list.';
                break;
            }

            return (
              <AccordionItem key={seed(linkingError)} title={linkingError.citation}>
                <Paragraph>{message}</Paragraph>

                <div className="mb-4">
                  {linkingError.citation_instances?.map((link, index) => (
                    <Fragment key={seed(link)}>
                      <Card className="p-4 mt-4" hero>
                        <Paragraph>
                          {'Note: The entry '}
                          <span className="font-mono bg-yellow-100">
                            {link.citation_text.trim()}
                          </span>
                          {' refers to '}
                          <span className="font-mono bg-yellow-100">
                            {link.citation_text_extended.trim()}
                          </span>
                        </Paragraph>
                      </Card>

                      {linkingError.documents?.map((doc) => (
                        <Card className="p-4 mt-4" hero key={seed(doc)}>
                          <Ul listStyle="none">
                            <ListItem>#: {doc.document_number || 'N/A'}</ListItem>
                            <ListItem>Title: {doc.document_title || 'N/A'}</ListItem>
                            <ListItem>Type: {doc.document_type || 'N/A'}</ListItem>
                            <ListItem>Sub-type: {doc.document_subtype || 'N/A'}</ListItem>
                            <ListItem>Filed Date: {doc.filed_date || 'N/A'}</ListItem>
                            <ListItem>Transcript Date: {doc.transcript_date || 'N/A'}</ListItem>
                            <ListItem>Unique ID: {doc.uniquedocid || 'N/A'}</ListItem>
                            <ListItem>
                              Is Supplemental: {doc.is_supplemental ? 'Yes' : 'No'}
                            </ListItem>
                          </Ul>
                          <Button
                            fullWidth
                            variant={
                              selectedFiles[linkingError.citation][index] === doc.link
                                ? 'tertiary'
                                : 'primary'
                            }
                            onClick={() =>
                              setSelectedFiles((prev) => {
                                const clone = [...prev[linkingError.citation]];
                                clone[index] = doc.link;

                                return { ...prev, [linkingError.citation]: clone };
                              })
                            }>
                            {selectedFiles[linkingError.citation][index] === doc.link
                              ? 'Selected'
                              : 'Select'}
                          </Button>
                        </Card>
                      ))}
                    </Fragment>
                  ))}
                </div>
              </AccordionItem>
            );
          })}
        </Accordion>

        <div className="flex flex-col lg:flex-row items-center justify-between mt-4 w-full px-4">
          <HelpText className="text-center mr-4">
            Bypass action items &amp; <Anchor href={downloadLink}>download your file</Anchor>
          </HelpText>

          {linkingErrors.filter(propEq('error_type', 'multiple-results')).length > 0 && (
            <Button
              variant="secondary"
              className="ml-auto mr-2 w-full lg:w-auto mb-4 lg:mb-0"
              onClick={() => setFormStep(UploadStep.Upload)}>
              Re-submit
            </Button>
          )}

          <Button className="w-full lg:w-auto" variant="primary" onClick={resetForm}>
            Start Over
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <form
      style={{ height: isProcess(formStep) ? 400 : 'auto' }}
      className="relative"
      ref={formRef}
      onSubmit={onSubmit}>
      <Container className="flex flex-col flex-1 bg-white w-full">
        <FileInput
          id={record.id}
          label="Upload Parent Document File (Brief, Motion, etc.)"
          helpText={renderHelpText()}
          file={record.file}
          canRemove={isPreparing(formStep)}
          onChange={([, file = null]) => {
            setRecord({ ...record, file });
            if (isAnonymous || !hasRequiredInfo) dispatch(saveTemporaryFile(file));
          }}
          placeholder="Click to add PDF file or drop PDF file here"
        />

        <Input
          label={InputLabel}
          className="mb-4"
          onChange={(evt) => {
            setCaseId(evt.target.value);
            if (isAnonymous || !hasRequiredInfo) dispatch(saveTemporaryCaseId(evt.target.value));
          }}
          onBlur={() => {
            if (caseId !== briefKey) setBriefKey('');
          }}
          defaultValue={caseId}
          onKeyDown={onKeyDown}
        />

        {isAnonymous && isPreparing(formStep) && (
          <div className="pb-2">
            <Checkbox
              defaultChecked={acceptTerms}
              onChange={setAcceptTerms}
              label={
                <Typography style={{ fontSize: 14 }}>
                  {'I have read and agree to the '}
                  <Anchor href={Paths.TermsOfUse} target="_blank">
                    Terms of Use
                  </Anchor>
                  {' and '}
                  <Anchor href={Paths.PrivacyPolicy} target="_blank">
                    Privacy Policy
                  </Anchor>
                </Typography>
              }
            />
          </div>
        )}

        {renderActions()}
      </Container>

      {renderLoading()}
    </form>
  );
};
